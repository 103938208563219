import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import "./css/styles.css";
import "./index.css";
import "./App.css";
import Footer from "./components/Footer";
import ChatPopUp from "./components/ChatPopUp";

const HomePage = React.lazy(() => import("./pages/HomePage"));
const TermsServices = React.lazy(() => import("./pages/TermsServices"));
const Policy = React.lazy(() => import("./pages/Policy"));
const Services = React.lazy(() => import("./pages/Services"));
const Solutions = React.lazy(() => import("./pages/Solutions"));
const AIChatGpt = React.lazy(() => import("./pages/AIChatGpt"));
const CloudStorage = React.lazy(() => import("./pages/CloudStorage"));
const DataAnalytics = React.lazy(() => import("./pages/DataAnalytics"));
const MobileAppDevelopement = React.lazy(() =>
  import("./components/Services/MobileAppDevelopement")
);
const AlMLIoTApps = React.lazy(() =>
  import("./components/Services/AlMLIoTApps")
);
const BigDataAnalytics = React.lazy(() =>
  import("./components/Services/Big-Data-Analytics")
);
const CloudServices = React.lazy(() =>
  import("./components/Services/CloudServices")
);
const CyberSecurity = React.lazy(() =>
  import("./components/Services/Cyber-Security")
);
const EnterpriseInformationManagement = React.lazy(() =>
  import("./components/Services/EnterpriseInformationManagement")
);
const ContactUs = React.lazy(() =>
  import("./components/contact-us/components/ContactUs")
);
const AppDeveloper = React.lazy(() =>
  import("./components/hire-developer/components/AppDeveloper")
);
const About = React.lazy(() => import("./components/About/About"));
const Careers = React.lazy(() => import("./components/Careers/Careers"));
const Pricing = React.lazy(() => import("./components/Pricing/Pricing"));
const CloudInfrastructure = React.lazy(() =>
  import("./components/Solutions/CloudInfrastructure")
);
const DigitalWorkplace = React.lazy(() =>
  import("./components/Solutions/DigitalWorkplace")
);
const TechnologyServices = React.lazy(() =>
  import("./components/Solutions/TechnologyServices")
);
const ServicesCloud = React.lazy(() =>
  import("./components/DataCenter/ServicesCloud")
);
const CoLocations = React.lazy(() =>
  import("./components/DataCenter/CoLocations")
);
const NetworkSurveillance = React.lazy(() =>
  import("./components/DataCenter/NetworkSurveillance")
);
const BigDataTechnologies = React.lazy(() =>
  import("./components/Technology/BigDataTechnologies")
);
const MicrosoftPowerbi = React.lazy(() =>
  import("./components/Technology/MicrosoftPowerbi")
);
const ErpOdooSaas = React.lazy(() =>
  import("./components/Technology/ErpOdooSaas")
);
const Flutter = React.lazy(() =>
  import("./components/flutter/components/Flutter_")
);
// const Flutter = React.lazy(() =>
//   import("./components/flutter/components/Flutter")
// );
const EurosomDataPlatform = React.lazy(() =>
  import("./components/Technology/EurosomDataPlatform")
);
const ChatgptApi = React.lazy(() =>
  import("./components/Technology/ChatgptApi")
);
const AppDevelopment = React.lazy(() =>
  import("./components/learning/components/appdevelopment/AppDevelopment")
);
const ArtificialInteligence = React.lazy(() =>
  import(
    "./components/learning/components/artificialinteligence/ArtificialInteligence"
  )
);
const DataAnalytic = React.lazy(() =>
  import("./components/learning/components/dataanalytics/DataAnalytic")
);
// const CyberSecurities = React.lazy(() =>
//   import("./components/learning/components/cybersecurity/CyberSecurities")
// );
const CyberSecurities = React.lazy(() =>
  import("./components/Services/Cyber-Security_")
);
const CloudComputings = React.lazy(() =>
  import("./components/learning/components/cloudcomputing/CloudComputings")
);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <NavigationBar />
      <div style={{ marginTop: 72, minHeight: "100vh" }} className="bg-light">
        <Suspense fallback={<div style={{ minHeight: "100vh" }}></div>}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/terms" element={<TermsServices />} />
            <Route path="/privacy" element={<Policy />} />
            {/* services routes */}
            <Route path="/services" element={<Services />} />
            <Route
              path="/services/cloud-services"
              element={<CloudServices />}
            />
            <Route
              path="/services/mobile-app-development"
              element={<MobileAppDevelopement />}
            />
            <Route
              path="/services/ai-ml-iot-applications"
              element={<AlMLIoTApps />}
            />
            <Route
              path="/services/big-data-analytics"
              element={<BigDataAnalytics />}
            />
            <Route
              path="/services/cyber-security"
              element={<CyberSecurity />}
            />
            <Route
              path="/services/enterprise-information-management"
              element={<EnterpriseInformationManagement />}
            />
            {/* solutions routes */}
            <Route path="/solutions" element={<Solutions />} />
            <Route
              path="/solutions/cloud-infrastructure"
              element={<CloudInfrastructure />}
            />
            <Route
              path="/solutions/digital-workplace"
              element={<DigitalWorkplace />}
            />
            <Route
              path="/solutions/technology-services"
              element={<TechnologyServices />}
            />
            {/* Data Center routes */}
            <Route
              path="/datacenter/cloud-services"
              element={<ServicesCloud />}
            />
            <Route path="/datacenter/co-locations" element={<CoLocations />} />
            <Route
              path="/datacenter/network-surveillance"
              element={<NetworkSurveillance />}
            />
            {/* Technology routes */}
            <Route
              path="/technology/big-data-technologies"
              element={<BigDataTechnologies />}
            />
            <Route
              path="/technology/microsoft-powerbi"
              element={<MicrosoftPowerbi />}
            />
            <Route path="/technology/erp-odoo-saas" element={<ErpOdooSaas />} />
            <Route
              path="/technology/flutter-programming"
              element={<Flutter />}
            />
            <Route
              path="/technology/eurosom-data-platform"
              element={<EurosomDataPlatform />}
            />
            <Route path="/technology/chatgpt-api" element={<ChatgptApi />} />
            <Route path="/app-developer" element={<AppDeveloper />} />
            <Route path="/cloud-storage" element={<CloudStorage />} />
            <Route path="/ai-chatgpt" element={<AIChatGpt />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/data-analytics" element={<DataAnalytics />} />
            <Route
              path="/learning/app-development"
              element={<AppDevelopment />}
            />
            <Route
              path="/learning/artificial-intiligence"
              element={<ArtificialInteligence />}
            />
            <Route
              path="/learning/cloud-computing"
              element={<CloudComputings />}
            />
            <Route path="/learning/data-analytics" element={<DataAnalytic />} />
            <Route
              path="/learning/cyber-security"
              element={<CyberSecurities />}
            />
            <Route path="/about" element={<About />} />
<Route path="/careers" element={<Careers />} />
 <Route path="/pricing" element={<Pricing />} />
          </Routes>
        </Suspense>
      </div>

       <Footer />
    </BrowserRouter>
  );
};

export default App;
